import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { getDefinedColumns } from './definedColumns';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';

// :: Component
import Loader from '../../components/Loader/Loader';
import StatusBar from '../../components/StatusBar/StatusBar';
import CustomizableDataGrid from '../../components/CustomizableDataGrid/CustomizableDataGrid';
import Heading from '../../components/Heading/Heading';
import DataGridControl from '../../components/DataGridControl/DataGridControl';
import Checkbox from '../../components/Checkbox/Checkbox';

// :: Contexts
import AppContext from '../../contexts/AppContext';
import UserContext from '../../contexts/UserContext';

// :: Hooks
import { useAllPlans } from '../../hooks/api';
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';
import useOnce from '../../hooks/useOnce';
import useActionsColumn from '../../components/DataGrid/useActions';
import { useGridFilters } from '../../components/DataGrid/useGridFilters';

// :: Icons
import { HouseIcon, WarningIcon } from '../../images/shapes';

// :: Lib
import {
  getTestProps,
  setInitialGridOptions,
  prepareColumns,
} from '../../lib/helpers';

// :: Utils
import { getLocalStorage, removeLocalStorage } from '../../utils/localStorage';

const parseFilters = (filters) => {
  const parsedFilters = {};

  Object.keys(filters || {}).forEach((key) => {
    if (filters[key].filter === 'false') {
      parsedFilters[key] = 0;
    } else if (filters[key].filter === 'true') {
      parsedFilters[key] = 1;
    } else {
      parsedFilters[key] = filters[key].filter;
    }
  });

  return parsedFilters;
};

const Plans = ({ testId }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const OPTIONS_KEY = 'cms.plans-grid-state';
  const SORT_KEY = 'cms.plans-sort';

  const { updateAppContext } = useContext(AppContext);
  const { isRoleAdmin } = useContext(UserContext);

  const plansGridContainer = useRef();

  const [sort, setSort] = useState(getLocalStorage(SORT_KEY, true));
  const [editGrid, setEditGrid] = useState(false);
  const [filterGrid, setFilterGrid] = useState(!!searchParams.get('filters'));
  const [initDataHasContent, setInitDataHasContent] = useState(false);

  const onlyVisible = !searchParams.get('hidden');
  const onOnlyVisibleChange = useCallback(
    (e) => {
      const visible = e.target.checked;
      setSearchParams((params) => {
        if (visible) params.delete('hidden');
        else params.set('hidden', true);
        return params;
      });
    },
    [setSearchParams],
  );

  const [columns, setColumns] = useState([]);
  const [gridOptions, setGridOptions] = useState(
    getLocalStorage(OPTIONS_KEY, true),
  );

  const {
    filters,
    handleFiltersChange,
    page,
    handlePageChange,
    limit,
    handleLimitChange,
    removeAllFilters,
  } = useGridFilters('plans');

  const handlePageUpdate = useCallback(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      page: 'admin/plans',
      menuItemOpen: 'admin',
      topBar: {
        heading: t('Global.Plans'),
        buttons: isRoleAdmin
          ? [
              {
                label: t('Plans.Form.AddPlan'),
                link: '/plans/add',
                disabled: false,
                key: 'Add',
              },
            ]
          : [],
      },
      breadcrumbs: [
        {
          label: <HouseIcon className="w-3 text-blue" />,
          link: '/',
          additionalClasses: 'text-slate-400 truncate text-center',
          key: 'Dashboard',
        },
        {
          label: t('Global.Plans'),
          additionalClasses: 'text-slate-400 truncate text-center',
          key: 'Plans',
        },
      ],
    }));
  }, [updateAppContext, t, isRoleAdmin]);

  useOnce(handlePageUpdate);

  const plansParams = useMemo(
    () => ({
      page: page,
      limit: limit,
      ...parseFilters(filters),
      ...(onlyVisible ? { visible: 1 } : {}),
      ...(sort?.sortOrder ? { order_direction: sort?.sortOrder } : {}),
      ...(sort?.sortField ? { order_by: sort?.sortField } : {}),
    }),
    [page, limit, filters, onlyVisible, sort?.sortOrder, sort?.sortField],
  );

  const allPlansOptions = useMemo(
    () => ({
      pause: !isRoleAdmin,
    }),
    [isRoleAdmin],
  );

  const {
    data: allPlans,
    isLoading: allPlansAreLoading,
    pagination: allPlansPagination,
    errors: allPlansErrors,
    reload: allPlansReload,
  } = useAllPlans(plansParams, allPlansOptions);

  useApiErrorsToast(allPlansErrors);

  useEffect(() => {
    if (allPlans.length && !initDataHasContent) {
      setInitDataHasContent(true);
    }
  }, [allPlans.length, initDataHasContent]);

  useEffect(() => {
    if (allPlansPagination?.total_pages < page)
      handlePageChange(allPlansPagination.total_pages);
  }, [handlePageChange, page, allPlansPagination?.total_pages]);

  const noAccess = useMemo(() => {
    return (
      <Heading
        level={2}
        additionalClasses="text-3xl md:text-4xl leading-8 dark:text-white"
      >
        <div
          className="flex flex-col items-center justify-center text-center"
          {...getTestProps(testId, 'no-access')}
        >
          <WarningIcon className="text-red w-14 md:w-20 mb-3" />
          {t('Global.NoAccess')}
        </div>
      </Heading>
    );
  }, [t, testId]);

  const emptyResult = useMemo(() => {
    if (!isRoleAdmin) {
      return noAccess;
    }
    if (initDataHasContent) return null;
    if (allPlansAreLoading) {
      return (
        <Loader
          size="small"
          type="spinner-grid"
          {...getTestProps(testId, 'loader', 'testId')}
        />
      );
    }
    return (
      <Heading
        level={2}
        additionalClasses={'text-3xl md:text-4xl leading-8 dark:text-white'}
      >
        <div
          className="flex flex-col items-center justify-center text-center"
          {...getTestProps(testId, 'empty-data')}
        >
          <WarningIcon
            className="text-red w-14 md:w-20 mb-3"
            title={t('Plans.CouldntFind')}
          />
          {t('Plans.CouldntFind')}
        </div>
      </Heading>
    );
  }, [
    isRoleAdmin,
    initDataHasContent,
    allPlansAreLoading,
    testId,
    t,
    noAccess,
  ]);

  useEffect(() => {
    setGridOptions(getLocalStorage(OPTIONS_KEY, true));
  }, [OPTIONS_KEY]);

  useEffect(() => {
    setSort(getLocalStorage(SORT_KEY, true));
  }, [SORT_KEY]);

  const handleResetGrid = useCallback(() => {
    setSort();
    removeAllFilters();
    removeLocalStorage(SORT_KEY);
    removeLocalStorage(OPTIONS_KEY);
    setGridOptions();
  }, [removeAllFilters]);

  const handleInitialGridOptions = useCallback(
    (cols, minWidth) => {
      setInitialGridOptions(
        cols,
        minWidth,
        plansGridContainer.current.offsetWidth - 50,
        setGridOptions,
        setColumns,
        OPTIONS_KEY,
      );
    },
    [OPTIONS_KEY],
  );

  useEffect(() => {
    prepareColumns(
      gridOptions,
      OPTIONS_KEY,
      editGrid,
      t,
      testId,
      setGridOptions,
      handleInitialGridOptions,
      setColumns,
      getDefinedColumns,
    );
  }, [editGrid, gridOptions, handleInitialGridOptions, t, testId]);

  const actionColumn = useActionsColumn('/plans');

  const allColumns = useMemo(
    () => [actionColumn, ...columns],
    [columns, actionColumn],
  );

  return (
    <div className="w-full md:h-[calc(100vh-66px)]">
      <Helmet>
        <title>{t('Global.Plans')}</title>
      </Helmet>
      <div className="flex flex-col h-full w-full">
        <div className="flex flex-col h-full w-full">
          <div
            className={twMerge(
              'flex flex-wrap sm:flex-nowrap items-center justify-between relative',
              'px-3 lg:px-7 py-2 h-auto sm:h-11 w-full z-10 bg-slate-50 border-b gap-3 sm:gap-0',
              'dark:bg-gray-900 dark:border-b dark:border-slate-800',
            )}
          >
            <DataGridControl
              displayGridCheckboxes={initDataHasContent && columns.length > 0}
              displayGridControl={initDataHasContent}
              editGrid={editGrid}
              filterGrid={filterGrid}
              setEditGrid={setEditGrid}
              setFilterGrid={setFilterGrid}
              handleResetGrid={handleResetGrid}
              limit={limit}
              handleLimitChange={handleLimitChange}
              columns={columns}
              gridOptions={gridOptions}
              optionsLocalStorageKey={OPTIONS_KEY}
              setGridOptions={setGridOptions}
              testId={testId}
            />
          </div>
          {isRoleAdmin && (
            <Checkbox
              name="visible"
              checked={onlyVisible}
              onChange={onOnlyVisibleChange}
              label={t('Plans.DisplayVisible')}
              additionalContainerClasses="px-5 lg:px-7 py-2"
              additionalLabelClasses={twMerge('ml-2 text-sm md:text-base mr-2')}
              additionalCheckboxClasses="mt-0 lg:mt-0.5 h-4 md:h-5 w-4 md:w-5
                bg-[length:8px_8px] md:bg-[length:10px_10px] bg-white border-neutral-300 checked:border-blue"
              {...getTestProps(testId, 'only-visible', 'testId')}
            />
          )}
          <div
            className="w-full px-5 lg:px-7 rounded-lg h-[calc(100vh-15rem)] md:h-[calc(100%-190px)]"
            ref={plansGridContainer}
          >
            {initDataHasContent ? (
              <CustomizableDataGrid
                columns={allColumns}
                setColumns={setColumns}
                data={allPlans}
                isLoading={allPlansAreLoading}
                setSort={setSort}
                sort={sort?.sortField || undefined}
                sortOrder={sort?.sortOrder || undefined}
                sortingLocalStorageKey={SORT_KEY}
                optionsLocalStorageKey={OPTIONS_KEY}
                setCurrentPage={handlePageChange}
                editGrid={editGrid}
                gridOptions={gridOptions}
                setGridOptions={setGridOptions}
                statusBar={
                  <StatusBar
                    rows={allPlansPagination.count}
                    currentPage={page}
                    pagesCount={allPlansPagination.total_pages}
                    handlePageChange={handlePageChange}
                    handleDataUpdate={allPlansReload}
                    resultsFrom={
                      (allPlansPagination.current_page - 1) * limit + 1
                    }
                    resultsTo={
                      (allPlansPagination.current_page - 1) * limit +
                      allPlansPagination.count
                    }
                    resultsTotalCount={allPlansPagination.total_count}
                    {...getTestProps(testId, 'statusbar', 'testId')}
                  />
                }
                noDataInfoText={t('Plans.FiltersEmptyResult')}
                hasFilters={filterGrid}
                filters={filters}
                disableFilters={editGrid}
                onFilter={handleFiltersChange}
                additionalClasses="bg-white dark:bg-gray-900"
                {...getTestProps(testId, 'grid', 'testId')}
              />
            ) : (
              <div className="flex flex-col items-center justify-center h-full dark:text-white">
                {emptyResult}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plans;

Plans.propType = {
  /**
   * Test id for plans page
   */
  testId: PropTypes.string,
};

Plans.defaultProps = {
  testId: '',
};
