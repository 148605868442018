import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button from '../../Button/Button';
import { getTestProps } from '../../../lib/helpers';
import { DeleteIcon, WarningTriangleIcon } from '../../../images/shapes';
import { FieldArray, getIn } from 'formik';
import { twMerge } from 'tailwind-merge';
import Dropdown from '../../Dropdown/Dropdown';
import UserContext from '../../../contexts/UserContext';

const UserRolesField = ({
  arrayHelpers,
  userRoles,
  disabled,
  isNew,
  testId,
}) => {
  const { t } = useTranslation();
  const { isAdmin } = useContext(UserContext);

  const value = useMemo(
    () => getIn(arrayHelpers.form.values, arrayHelpers.name) || [],
    [arrayHelpers.form.values, arrayHelpers.name],
  );

  const placeholder = useMemo(() => {
    if (!userRoles?.length) return t('UserRoles.RoleSelector.EmptyRoles');
    return t('UserRoles.RoleSelector.PlaceHolder');
  }, [t, userRoles?.length]);

  return (
    <Dropdown
      name={arrayHelpers.name}
      options={userRoles || []}
      value={value}
      onChange={arrayHelpers.form.handleChange}
      onBlur={arrayHelpers.form.handleBlur}
      placeholder={placeholder}
      helpText={
        value.length === 0 &&
        !isNew &&
        isAdmin && (
          <div className="!text-yellow-600 flex items-center ">
            <WarningTriangleIcon className="w-5 h-5 mr-0 md:mr-2" />
            <label className="!break-normal	">
              {t('UserRoles.RoleSelector.NoRoleSelectedWarning')}
            </label>
          </div>
        )
      }
      additionalClasses="w-full"
      disabled={isNew || disabled}
      multiple
      nullable
      {...getTestProps(testId, 'roles', 'testId')}
    />
  );
};

const SpaceSelectorElement = ({
  arrayHelpers,
  idx,
  spaceId,
  onRemove,
  availableSpaces,
  allSpaces,
  userRolesBySpace,
  disabled,
  testId,
}) => {
  const { t } = useTranslation();
  const { isAdmin } = useContext(UserContext);

  const removeSpace = useCallback(() => {
    onRemove(idx);
  }, [idx, onRemove]);

  const onSpaceChange = useCallback(
    (_, spaceId) => {
      arrayHelpers.replace(idx, { id: spaceId, userRoles: [] });
    },
    [arrayHelpers, idx],
  );

  return (
    <div
      className={twMerge(
        'flex flex-col items-center md:flex-row md:justify-between md:items-start gap-2',
        !spaceId && 'md:mr-11',
      )}
    >
      <Dropdown
        name={`${arrayHelpers.name}.${idx}.id`}
        value={spaceId}
        onChange={onSpaceChange}
        onBlur={arrayHelpers.form.handleBlur}
        options={availableSpaces}
        extraOptions={allSpaces}
        placeholder={t('Spaces.SpaceSelector.PlaceHolder')}
        emptyOptions={
          <div className="py-2 px-4">
            {t('Spaces.SpaceSelector.EmptySpaces')}
          </div>
        }
        additionalClasses="w-full"
        disabled={disabled}
        hideSearch
        {...getTestProps(testId, 'space', 'testId')}
      />

      <FieldArray name={`${arrayHelpers.name}.${idx}.userRoles`}>
        {(userRolesArrayHelpers) => (
          <UserRolesField
            arrayHelpers={userRolesArrayHelpers}
            userRoles={userRolesBySpace[spaceId]}
            disabled={disabled}
            isNew={!spaceId}
            testId={testId}
          />
        )}
      </FieldArray>

      {spaceId && isAdmin && (
        <Button
          buttonColor="borderless"
          additionalClasses="w-fit h-full px-2 md:py-3.5"
          onClick={removeSpace}
          disabled={disabled}
          {...getTestProps(testId, `delete-space-input-button-${spaceId}`)}
        >
          <DeleteIcon className={'w-5 h-5'} />
        </Button>
      )}
    </div>
  );
};

export default SpaceSelectorElement;

SpaceSelectorElement.propTypes = {
  /**
   * Formik array helpers
   */
  arrayHelpers: PropTypes.object.isRequired,
  /**
   * Index from spaces value
   */
  idx: PropTypes.number.isRequired,
  /**
   * Selected space
   */
  spaceId: PropTypes.string,
  /**
   * On remove callback
   */
  onRemove: PropTypes.func,
  /**
   * Spaces from users organization filtered by selected spaces
   */
  availableSpaces: PropTypes.arrayOf(PropTypes.object),
  /**
   * All spaces from users organization
   */
  allSpaces: PropTypes.arrayOf(PropTypes.object),
  /**
   * All user roles organized by space id
   */
  userRolesBySpace: PropTypes.object,
  /**
   * If field is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Test id for SpaceSelectorElement
   */
  testId: PropTypes.string,
};

SpaceSelectorElement.defaultProps = {
  availableSpaces: [],
  allSpaces: [],
  userRolesBySpace: {},
  onRemove: /* istanbul ignore next */ () => null,
  disabled: false,
  testId: '',
};
